import { useEffect, useState } from 'react';

export const useDrawCanvas = (
  canvasRef: React.RefObject<HTMLCanvasElement>,
  selectedImage: HTMLImageElement | null,
  videoRef: React.RefObject<HTMLVideoElement>,
  mediaStream: MediaStream | null,
  captionText: string
) => {
  const [backgroundImage, setBackgroundImage] = useState<HTMLImageElement | null>(null);

  // Effect for loading the background image
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');
    if (canvas && context) {
      if (window.devicePixelRatio > 1) {
        console.log('Updating canvas size for pixel ratio...');
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;
        canvas.width = canvasWidth * window.devicePixelRatio;
        canvas.height = canvasHeight * window.devicePixelRatio;
      } else {
        console.log('No need to update canvas size');
      }

      const backgroundImage = new Image();
      backgroundImage.onload = () => {
        canvas.width = backgroundImage.width;
        canvas.height = backgroundImage.height;
        setBackgroundImage(backgroundImage);
        drawImageAndCaptionAndVideo(backgroundImage, selectedImage, captionText, canvas, context, videoRef, mediaStream);
      };
      backgroundImage.src = '/template5.png';
    }
  }, []);

  // Effect for drawing the selected image and caption text
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');
    if (canvas && context && backgroundImage) {
      drawImageAndCaptionAndVideo(backgroundImage, selectedImage, captionText, canvas, context, videoRef, mediaStream);
    }
  }, [selectedImage, captionText]);
};

const drawImageAndCaptionAndVideo = (
  backgroundImage: HTMLImageElement,
  selectedImage: HTMLImageElement | null,
  caption: string,
  canvas: HTMLCanvasElement,
  context: CanvasRenderingContext2D,
  videoRef: React.RefObject<HTMLVideoElement>,
  mediaStream: MediaStream | null
) => {
  context.clearRect(0, 0, canvas.width, canvas.height);
  context.drawImage(backgroundImage, 0, 0);

  const fontSize = 160;
  const textX = canvas.width * 0.18;
  const textY = canvas.height * 0.71 + fontSize;
  const maxWidth = canvas.width * (1.0 - 0.18 * 2);

  context.font = `${fontSize}px DIN`;
  context.fillStyle = 'white';

  const wrappedText = wrapText(context, caption, textX, textY, maxWidth, fontSize);
  wrappedText.forEach(function (line) {
    context.fillText(line.text, line.x, line.y);
  });

  const video = videoRef.current;
  if (selectedImage) {
    drawSourceInCanvas(canvas, context, selectedImage, selectedImage.width, selectedImage.height);
  } else if (mediaStream != null && video != null) {
    drawSourceInCanvas(canvas, context, video, video.videoWidth, video.videoHeight);
  }
};

export const drawSourceInCanvas = (
  canvas: HTMLCanvasElement,
  context: CanvasRenderingContext2D,
  source: CanvasImageSource,
  sourceWidth: number,
  sourceHeight: number
) => {
  // top: 7.4%;
  // left: 36.77%;
  // width: 46.38%;
  // height: 46.5%;
  const yOffset = canvas.height * 0.074;
  const xOffset = canvas.width * 0.3677;
  const boxWidth = canvas.width * 0.4638;
  const boxHeight = canvas.height * 0.465;

  const boxRatio = boxWidth / boxHeight;
  const sourceRatio = sourceWidth / sourceHeight;
  if (boxRatio > sourceRatio) {
    // Box is wider than source, crop top and bottom off source
    const newHeight = sourceWidth / boxRatio;
    const newYOffset = (sourceHeight - newHeight) / 2;
    context.drawImage(source, 0, newYOffset, sourceWidth, newHeight, xOffset, yOffset, boxWidth, boxHeight);
  } else {
    // Source is wider than box, crop left and right off source
    const newWidth = sourceHeight * boxRatio;
    const newXOffset = (sourceWidth - newWidth) / 2;
    context.drawImage(source, newXOffset, 0, newWidth, sourceHeight, xOffset, yOffset, boxWidth, boxHeight);
  }
};

interface Line {
  text: string;
  x: number;
  y: number;
}

/// https://fjolt.com/article/html-canvas-how-to-wrap-text
const wrapText = (context: CanvasRenderingContext2D, text: string, x: number, y: number, maxWidth: number, lineHeight: number): Line[] => {
  // First, start by splitting all of our text into words, but splitting it into an array split by spaces
  const words = text.split(' ');
  let line = ''; // This will store the text of the current line
  let testLine = ''; // This will store the text when we add a word, to test if it's too long
  const lineArray: Line[] = []; // This is an array of lines, which the function will return

  // Lets iterate over each word
  for (let n = 0; n < words.length; n++) {
    // Create a test line, and measure it..
    testLine += `${words[n]} `;
    const metrics = context.measureText(testLine);
    const testWidth = metrics.width;
    // If the width of this test line is more than the max width
    if (testWidth > maxWidth && n > 0) {
      // Then the line is finished, push the current line into "lineArray"
      lineArray.push({ text: line, x, y });
      // Increase the line height, so a new line is started
      y += lineHeight;
      // Update line and test line to use this word as the first word on the next line
      line = `${words[n]} `;
      testLine = `${words[n]} `;
    } else {
      // If the test line is still less than the max width, then add the word to the current line
      line += `${words[n]} `;
    }
    // If we never reach the full max width, then there is only one line.. so push it into the lineArray so we return something
    if (n === words.length - 1) {
      lineArray.push({ text: line, x, y });
    }
  }
  // Return the line array
  return lineArray;
};
