import { FC, useState, useRef, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { styled } from 'styled-components';
import { CardView, UserSelection } from './CardView';
import { InstructionBox } from './InstructionBox';

export const CardContainer: FC = () => {
  const [isCaptureReadyWithSelection, setIsCaptureReadyWithSelection] = useState(UserSelection.None);
  const [captionText, setCaptionText] = useState('');
  const [isStreamPaused, setIsStreamPaused] = useState(false);
  const [imageDataURL, setImageDataURL] = useState<string | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // Google analytics page view tracking
  useEffect(() => {
    const page = imageDataURL === null ? '/' : '/preview';
    ReactGA.send({ hitType: 'pageview', page: page });
  }, [imageDataURL]);

  // If the user navigates back, reset the canvas
  useEffect(() => {
    addEventListener('popstate', () => {
      setIsStreamPaused(false);
      setImageDataURL(null);
    });
  }, []);

  // Update URL bar if we're not previewing
  useEffect(() => {
    if (window.location.pathname !== '/' && imageDataURL == null) {
      console.log('Navigating to root...');
      history.replaceState('', '', '/');
    }
  }, [window.location.pathname]);

  const onClickTrailerLink = () => {
    ReactGA.event({ category: 'Trailer', action: 'clicked_trailer' });
  };

  const onCaptureReady = (selection: UserSelection) => {
    setIsCaptureReadyWithSelection(selection);
    ReactGA.event({ category: 'Selection', action: 'user_selection' }, { selection: nameForSelection(selection) });
  };
  const isCaptureReady = isCaptureReadyWithSelection !== UserSelection.None;
  const nextButtonText = isCaptureReadyWithSelection === UserSelection.Camera && !isStreamPaused ? 'TAKE PHOTO' : 'NEXT';

  const shareText = isShareSupported() ? 'SHARE' : 'DOWNLOAD';
  return (
    <Container>
      <Background />
      <Content>
        {imageDataURL == null && (
          <>
            <CardView canvasRef={canvasRef} captionText={captionText} isStreamPaused={isStreamPaused} onCaptureReady={onCaptureReady} />
            <TextField type="text" placeholder="Enter caption" onChange={(event) => setCaptionText(event.target.value)} />
            <NextShareButton
              disabled={!isCaptureReady}
              onClick={previewImage(canvasRef, setImageDataURL, isCaptureReadyWithSelection, isStreamPaused, setIsStreamPaused)}
            >
              {nextButtonText}
            </NextShareButton>
          </>
        )}
        {imageDataURL && (
          <>
            <PreviewImage src={imageDataURL} />
            <InstructionBox share={isShareSupported()} />
            <NextShareButton onClick={onShareDownload(imageDataURL)}>{shareText}</NextShareButton>
            <TrailerLink href="https://www.youtube.com/watch?v=moiRCJR4ToY" target="_blank" rel="noreferrer" onClick={onClickTrailerLink}>
              Watch The Blackening Trailer
            </TrailerLink>
          </>
        )}
      </Content>
    </Container>
  );
};

// Helper functions

const isShareSupported = (): boolean => typeof navigator.share === 'function';

const nameForSelection = (selection: UserSelection): string => {
  switch (selection) {
    case UserSelection.None:
      return 'none';
    case UserSelection.Camera:
      return 'camera';
    case UserSelection.Upload:
      return 'upload';
    default:
      return 'unknown';
  }
};

const previewImage =
  (
    canvasRef: React.RefObject<HTMLCanvasElement>,
    setImageDataURL: React.Dispatch<React.SetStateAction<string | null>>,
    userSelection: UserSelection,
    isStreamPaused: boolean,
    setIsStreamPaused: React.Dispatch<React.SetStateAction<boolean>>
  ) =>
  () => {
    const canvas = canvasRef.current;
    if (canvas == null) {
      console.error('Canvas ref is unexpectedly null');
      return;
    }

    if (userSelection === UserSelection.Camera && !isStreamPaused) {
      // If the user selected the camera and the stream isn't paused yet, just pause the stream and return early.
      // The user must click the button again to advance.
      setIsStreamPaused(true);
      return;
    }

    const dataURL = canvas.toDataURL('image/png');
    setImageDataURL(dataURL);
    window.history.pushState(dataURL, '', '/preview');
  };

const onShareDownload = (imageDataURL: string) => () => {
  if (isShareSupported()) {
    fetch(imageDataURL)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], 'blackening-card.png', { type: 'image/png' });
        navigator
          .share({ files: [file] })
          .then(() => {
            ReactGA.event({ category: 'Share', action: 'share_completed' });
          })
          .catch((err) => console.error(`Failed to share: ${err}`));
      })
      .catch((err) => {
        console.error(`Failed to create share blob with error: ${err}`);
      });
  } else {
    const link = document.createElement('a');
    link.download = 'blackening-card.png';
    link.href = imageDataURL;
    link.click();
    ReactGA.event({ category: 'Download', action: 'download_completed' });
  }
};

// Styled components

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-height: 730px) {
    justify-content: flex-start;
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url(/background3.jpg) no-repeat center;
  background-size: cover;
  opacity: 0.5;
  z-index: -10;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;

  margin: auto;
  max-width: 400px;
  padding: 40px 0px;
`;

const TextField = styled.input`
  padding: 14px 14px;
  margin: 0 50px 10px 50px;

  border-width: 1px;
  border-style: solid;
  border-color: #d0d5dd;
  border-radius: 14px;

  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #101828;

  ::placeholder {
    color: #667085;
  }

  &:focus {
    outline-color: #101828;
  }
`;

const NextShareButton = styled.button`
  margin: 0 50px;
  height: 52px;
  color: white;
  background-color: rgb(231, 37, 31);
  border-radius: 14px;
  border: none;

  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 18px;

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }

  &:disabled {
    opacity: 1;
    background-color: rgb(159, 159, 159);
    cursor: initial;
  }
`;

const PreviewImage = styled.img`
  width: 100%;
`;

const TrailerLink = styled.a`
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: rgb(231, 37, 31);
  text-align: center;
  margin-top: 10px;
`;
