import { FC, useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { toast } from 'react-toastify';
import { CameraPermission, useCamera } from '../hooks/useCamera';
import { drawSourceInCanvas, useDrawCanvas } from '../hooks/useDrawCanvas';

const VIDEO_STREAM_DRAW_INTERVAL = 1000.0 / 10.0;

export enum UserSelection {
  None,
  Camera,
  Upload,
}

interface Props {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  captionText: string;
  isStreamPaused: boolean;
  onCaptureReady(selection: UserSelection): void;
}

export const CardView: FC<Props> = ({ canvasRef, captionText, isStreamPaused, onCaptureReady }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedImage, setSelectedImage] = useState<HTMLImageElement | null>(null);
  const [timerID, setTimerID] = useState<NodeJS.Timer | null>(null);
  const [userSelection, setUserSelection] = useState(UserSelection.None);

  // Start video capture if permissions are already granted
  const { permission, mediaStream, requestPermission, stopStreaming } = useCamera();

  // Draw background, selected image, and text
  useDrawCanvas(canvasRef, selectedImage, videoRef, mediaStream, captionText);

  useEffect(() => {
    if (userSelection === UserSelection.Camera) {
      console.log(`Attempting to start capture...`);
      startVideoCaptureIfNecessary(permission, mediaStream, videoRef, canvasRef, isStreamPaused, timerID, setTimerID, onCaptureReady);
    }
  }, [permission, mediaStream, videoRef, canvasRef, isStreamPaused, userSelection]);

  // Notify capture is ready when image is selected
  useEffect(() => {
    if (selectedImage) {
      if (timerID != null) {
        // Stop existing timer
        clearInterval(timerID);
        setTimerID(null);
      }
      onCaptureReady(userSelection);
      stopStreaming();
    }
  }, [selectedImage]);

  // Update user selection if permission denied
  useEffect(() => {
    if (userSelection == UserSelection.Camera && permission == CameraPermission.RejectedOrUnavailable) {
      setUserSelection(UserSelection.None);
      showCameraPermissionError();
    }
  }, [userSelection, permission]);

  // Capture still image from video stream
  useEffect(() => {
    const video = videoRef.current;
    if (video != null && mediaStream != null && isStreamPaused) {
      console.log('Capturing still image from stream...');
      captureStillCameraImage(video, setSelectedImage);
    }
  }, [mediaStream, isStreamPaused]);

  const selectUpload = () => browseForImageAndDrawInCanvas(inputRef, setSelectedImage, setUserSelection);
  const selectCamera = () => {
    if (permission !== CameraPermission.RejectedOrUnavailable) {
      setUserSelection(UserSelection.Camera);
      requestPermission();
    } else {
      showCameraPermissionError();
    }
  };

  const hideMediaButtons = userSelection !== UserSelection.None;
  return (
    <Content>
      <Canvas ref={canvasRef} />
      <VideoPreview ref={videoRef} hidden={mediaStream == null || isStreamPaused} autoPlay playsInline />
      <HiddenInput ref={inputRef} type="file" accept="image/*" />
      <MediaButtonsContainer hidden={hideMediaButtons}>
        <MediaButton onClick={selectUpload}>
          <img src="/photo.png" />
          UPLOAD PHOTO
        </MediaButton>
        <MediaButton onClick={selectCamera} disabled={permission == CameraPermission.Requesting}>
          <img src="/camera.png" />
          TAKE PHOTO
        </MediaButton>
      </MediaButtonsContainer>
    </Content>
  );
};

// Helper functions

const showCameraPermissionError = () => toast('No camera access. Check Settings to make sure this app can access the camera.');

const browseForImageAndDrawInCanvas = (
  inputRef: React.RefObject<HTMLInputElement>,
  setSelectedImage: React.Dispatch<React.SetStateAction<HTMLImageElement | null>>,
  setUserSelection: React.Dispatch<React.SetStateAction<UserSelection>>
) => {
  const input = inputRef.current;
  if (input == null) {
    console.error('Unexpectedly found null input ref');
    return;
  }

  input.onchange = () => {
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log(`File selected: ${file}`);
      const reader = new FileReader();
      reader.onload = () => {
        const image = new Image();
        image.onload = () => {
          setSelectedImage(image);
          setUserSelection(UserSelection.Upload);
        };
        image.src = reader.result as any;
      };
      reader.readAsDataURL(file);
    }
  };

  input.click();
};

const startVideoCaptureIfNecessary = (
  permission: CameraPermission,
  mediaStream: MediaStream | null,
  videoRef: React.RefObject<HTMLVideoElement>,
  canvasRef: React.RefObject<HTMLCanvasElement>,
  isStreamPaused: boolean,
  timerID: NodeJS.Timer | null,
  setTimerID: React.Dispatch<React.SetStateAction<NodeJS.Timer | null>>,
  onCaptureReady: (selection: UserSelection) => void
) => {
  if (permission != CameraPermission.Approved || mediaStream == null || isStreamPaused) return;
  const video = videoRef.current;
  if (video == null) {
    console.log('null video ref.');
    return;
  }

  console.log('Got stream, setting as source of video ref...');
  video.srcObject = mediaStream;

  const canvas = canvasRef.current;
  if (canvas == null) {
    console.error('Canvas is unexpectedly null');
    return;
  }

  if (timerID != null) {
    // Stop existing timer
    clearInterval(timerID);
    setTimerID(null);
  }

  const newTimerID = setInterval(renderVideoFrameToCanvas(canvas, video, isStreamPaused), VIDEO_STREAM_DRAW_INTERVAL);
  setTimerID(newTimerID);
  onCaptureReady(UserSelection.Camera);
};

const renderVideoFrameToCanvas = (canvas: HTMLCanvasElement, video: HTMLVideoElement, isStreamPaused: boolean) => () => {
  const context = canvas.getContext('2d');
  if (context != null && !isStreamPaused) {
    drawSourceInCanvas(canvas, context, video, video.videoWidth, video.videoHeight);
  }
};

const captureStillCameraImage = (
  video: HTMLVideoElement,
  setSelectedImage: React.Dispatch<React.SetStateAction<HTMLImageElement | null>>
) => {
  const canvas = document.createElement('canvas');
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  const context = canvas.getContext('2d');
  if (context == null) {
    console.error('Canvas context was unexpectedly null');
    return;
  }

  context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

  const stillImage = new Image();
  stillImage.onload = () => setSelectedImage(stillImage);
  stillImage.src = canvas.toDataURL('image/png');
};

// Styled components

const Content = styled.div`
  position: relative;
  aspect-ratio: 4 / 5;
  width: 100%;
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
`;

interface MediaButtonsContainerProps {
  hidden: boolean;
}

const MediaButtonsContainer = styled.div<MediaButtonsContainerProps>`
  position: absolute;
  top: 7.4%;
  left: 36.77%;
  width: 46.38%;
  height: 46.5%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 18px;
  padding: 8px;

  background-color: rgb(159, 159, 159);

  opacity: ${(props) => (props.hidden ? 0.0 : 1.0)};
  pointer-events: ${(props) => (props.hidden ? 'none' : 'inherit')};
  transition: opacity 200ms ease;

  @media screen and (max-height: 730px) {
    top: 7.2%;
    left: 36.77%;
    width: 46.5%;
    height: 46.3%;
  }
`;

const MediaButton = styled.button`
  width: 100%;
  height: 24%;
  border-radius: 14px;
  border: none;
  background-color: rgb(97, 97, 97);
  color: white;

  font-family: 'Roboto', sans-serif;
  font-weight: 700;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;

  & > img {
    height: 18px;
  }

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.3;
    cursor: inherit;
  }
`;

interface VideoPreviewProps {
  hidden: boolean;
}

const VideoPreview = styled.video<VideoPreviewProps>`
  position: absolute;
  top: 7.4%;
  left: 36.77%;
  width: 46.38%;
  height: 46.5%;

  object-fit: cover;
  opacity: ${(props) => (props.hidden ? '0.0' : '1.0')};
  transition: opacity 200ms ease;

  @media screen and (max-height: 730px) {
    top: 7.2%;
    left: 36.77%;
    width: 46.5%;
    height: 46.3%;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;
