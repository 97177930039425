import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

export enum CameraPermission {
  /// Permission is unknown
  Unknown,
  /// We do not have permission and we haven't attempted to prompt yet
  Unprompted,
  /// User has requested permissions, but the user has not approved or denied
  Requesting,
  /// We have prompted for permission and the prompt was either rejected or the camera is unavailable
  RejectedOrUnavailable,
  /// The user approved permissions
  Approved,
}

export interface CameraContext {
  permission: CameraPermission;
  mediaStream: MediaStream | null;
  /// Only call if `permission` is `Unprompted`
  requestPermission(): void;
  stopStreaming(): void;
}

export const useCamera = (): CameraContext => {
  const [permission, setPermission] = useState<CameraPermission>(CameraPermission.Unknown);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  const requestPermission = () => {
    setPermission(CameraPermission.Requesting);
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        setMediaStream(stream);
        setPermission(CameraPermission.Approved);
        ReactGA.event({ category: 'Video', action: 'permission_approved' });
      })
      .catch((err) => {
        console.error(`Permissions error: ${err}`);
        setMediaStream(null);
        setPermission(CameraPermission.RejectedOrUnavailable);
        ReactGA.event({ category: 'Video', action: 'permission_error' }, { error: err });
      });
  };

  const stopStreaming = () => {
    if (mediaStream != null) {
      mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
      setMediaStream(null);
    }
  };

  useEffect(() => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const videoDevice = devices.find((device) => device.kind === 'videoinput' && device.label.trim() !== '');
        if (videoDevice != null) {
          console.log(`User has granted permissions for device: ${videoDevice.label}`);
          setPermission(CameraPermission.Approved);
        } else {
          console.log('User has not granted permissions for video device');
          setPermission(CameraPermission.Unprompted);
        }

        // Testing
        // devices.forEach((device) => {
        //   if (device.label.trim() !== '') {
        //     console.log(`Found device: ${device.label.trim()}`);
        //   }
        // });
      })
      .catch((err) => {
        console.error(`did receive error when enumerating devices: ${err}`);
      });
  }, []);

  return {
    permission,
    mediaStream,
    requestPermission,
    stopStreaming,
  };
};
