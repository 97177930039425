import { FC } from 'react';
import { styled } from 'styled-components';

interface Props {
  share: boolean;
}

export const InstructionBox: FC<Props> = ({ share }) => {
  const text = share ? 'Tap and hold your Black Card to save to your photos' : 'Download your Black Card and share with friends';
  return (
    <OuterContainer>
      <InnerContainer>
        <InstructionLabel>{text}</InstructionLabel>
        <HashTagLabel>#BLACKENING&#8203;CARD&#8203;CHALLENGE</HashTagLabel>
        <SocialIconsBox>
          <SocialIcon src="/social/instagram.png" />
          <SocialIcon src="/social/twitter.png" />
          <SocialIcon src="/social/facebook.png" />
          <SocialIcon src="/social/snapchat.png" />
          <SocialIcon src="/social/tiktok.png" />
        </SocialIconsBox>
      </InnerContainer>
    </OuterContainer>
  );
};

// MARK: - Styled components

const OuterContainer = styled.div`
  background-color: black;
  border-radius: 20px;
  padding: 8px;
  margin: 0 50px;
`;

const InnerContainer = styled.div`
  border: 6px solid red;
  border-radius: 20px;

  width: 100%;
  height: 100%;
  padding: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
`;

const InstructionLabel = styled.h3`
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: white;

  @media screen and (max-width: 400px) {
    font-size: 16px;
  }
`;

const HashTagLabel = styled.h3`
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: rgb(231, 37, 31);

  @media screen and (max-width: 400px) {
    font-size: 14px;
  }
`;

const SocialIconsBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 18px;
`;

const SocialIcon = styled.img`
  width: 20px;
`;
